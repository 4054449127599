import store from 'store';
const USER_KEY = 'jump_user'

function getUser() {
  return store.get(USER_KEY) || {}
}

function setUser(val) {
  return store.set(USER_KEY, val)
}

function cleanUser() {
  store.remove(USER_KEY)
}

export const user = { clean: cleanUser, get: getUser, set: setUser }

export default { user }
