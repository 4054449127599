export const COMPLETE_DRY_CATEGORY = 'completedry';
export const COMPLETE_WET_CATEGORY = 'completewet';
export const COMPLEMENTARY_DRY_CATEGORY = 'complementarydry'
export const COMPLEMENTARY_WET_CATEGORY = 'complementarywet'
export const COMPLETE_DRY_PATHNAME = `/brand/${COMPLETE_DRY_CATEGORY}`
export const COMPLETE_WET_PATHNAME = `/brand/${COMPLETE_WET_CATEGORY}`
export const SEARCH_PRODUCT_PATHNAME = '/product/search'
export const USER_FAV_PATHNAME = '/user/favorite'
export const USER_PATHNAME = '/user'
export const LOGIN_PATHNAME = '/login'
export const LOGOUT_PATHNAME = '/logout'
export const ABOUT_PATHNAME = '/about'
export const PRIVACY_PATHNAME = '/privacy'
export const FB_PAGE = 'https://www.facebook.com/Purrmaster'
export const CALCULATOR_PATHNAME = '/calculator/catfood'
export const EDIT_CALCULATOR_PATHNAME = '/calculator/catfood/edit'
export const BLOG_NUTRITION_PATHNAME = '/blog/cat-nutrition-requirement'
export const BLOG_PLANT_PATHNAME = '/blog/cat-and-plant'
export const BLOG_CONTROVERSIAL_GUM_PATHNAME = '/blog/cat-controversial-gum'
export const COMPLETE_AND_SUPPLEMENT_PATHNAME = '/blog/complete-and-supplement'
export const TOP_10_PRODUCTS = '/blog/top-10-products'
export const FOOD_ANAYLYSIS = '/blog/food-analysis'
export const CAT_INSURANCE = '/blog/cat-insurance'
export const BLOG_PATHNAME = '/blog'

export const PRODUCT_CATEGORY_MAP = {
  'COMPLEMENTARY_DRY': COMPLEMENTARY_DRY_CATEGORY,
  'COMPLEMENTARY_WET': COMPLEMENTARY_WET_CATEGORY,
  'COMPLETE_DRY': COMPLETE_DRY_CATEGORY,
  'COMPLETE_WET': COMPLETE_WET_CATEGORY,
}
